import Vue from 'vue';
import {
  ENDPOINT_GUEST_AUTHORIZE,
} from '@/api/handler.endpoints';
import {
  POST_GUEST_AUTHORIZE,
} from '@/api/handler.methods';

export const getReservation = (reservationId, query) => {
  return Vue.axios.post(ENDPOINT_GUEST_AUTHORIZE, {
    reservation_id: reservationId,
    query,
  })
    .then(({data}) => data.responseBody || [])
    .catch((error) => {
      throw error;
    });
};

export default {
  [POST_GUEST_AUTHORIZE]: getReservation,
};
