<template>
  <div class="flash-messages-container">
    <div class="flash-messages">
      <Message v-for="messageData in messages"
        :key="messageData.index"
        :type="messageData.type || 'success'"
        :messageIndex="messageData.index"
        :message="messageData.message"
        @click="removeMessage"
      />
    </div>
  </div>
</template>

<script>
import Message from './Message';
import {
  GET_MESSAGES,
  REMOVE_MESSAGE,
} from '@/store/flashMessage';

export default {
  name: 'FlashMessages',
  components: {
    Message,
  },
  computed: {
    messages() {
      return this.$store.getters[GET_MESSAGES];
    },
  },
  methods: {
    removeMessage(messageIndex) {
      this.$store.dispatch(REMOVE_MESSAGE, messageIndex);
    },
  },
};
</script>

<style lang="scss">
  .flash-messages-container {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 5rem 1.5rem;
  }

  .flash-messages {
    display: flex;
    flex-direction: column;
  }
</style>
