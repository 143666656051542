var W3CWebSocket = require('websocket').w3cwebsocket;
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
import { STORAGE_ACCESS_PROPERTY_DATA } from '@/constants/storage';

export const startManagementCentrumWebsocketClient = () => {
  return new Promise((resolve, reject) => {
    if (!process.env.VUE_APP_KIOSK_MANAGEMENT_CENTRUM_ADDRESS) {
      reject();
    }

    var client = new W3CWebSocket(process.env.VUE_APP_KIOSK_MANAGEMENT_CENTRUM_ADDRESS + ':' + process.env.VUE_APP_KIOSK_MANAGEMENT_CENTRUM_PORT, 'echo-protocol', 'echo-protocol');

    client.onerror = function(err) {
      reject(err);
    };

    client.onclose = function() {
      setTimeout(() => {
        startManagementCentrumWebsocketClient()
          .then((connection) => {
            global.managementCentrumClient = connection;
          });
      }, 500);
    };

    client.onopen = function() {
      return resolve(client);
    };
  });
};

export const kioskManagerHearthbeat = () => {
  if (global.managementCentrumClient) {
    Storage.get({
      key: STORAGE_ACCESS_PROPERTY_DATA,
    }).then(({ value }) => {
      const accessData = value ? JSON.parse(value) : null;
      global.managementCentrumClient.send('KIOSK|INFO|HEARTHBEAT|' + process.env.VUE_APP_VERSION + '|' + (accessData ? accessData['pin'] : ''));
    });
  }
};

export const kioskManagerMessage = (message) => {
  if (global.managementCentrumClient) {
    Storage.get({
      key: STORAGE_ACCESS_PROPERTY_DATA,
    }).then(({ value }) => {
      const accessData = value ? JSON.parse(value) : null;
      global.managementCentrumClient.send('KIOSK|INFO|MESSAGE|' + message + '|' + (accessData ? accessData['pin'] : ''));
    });
  }
};
