import Vue from 'vue';
import {
  ENDPOINT_RESERVATION,
} from '@/api/handler.endpoints';
import {
  GET_RESERVATION,
} from '@/api/handler.methods';

export const getReservation = (reservationIdentifier) => {
  return Vue.axios.get(`${ENDPOINT_RESERVATION}/${reservationIdentifier}`)
    .then(({data}) => data.responseBody || [])
    .catch((error) => {
      throw error;
    });
};

export default {
  [GET_RESERVATION]: getReservation,
};
