import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
import { STORAGE_ACCESS_PROPERTY_DATA } from '@/constants/storage';

export const logEvent = (fbEvent, additionalOptions) => {
  if (!global.firebaseAnalytics) {
    return;
  }

  let options = additionalOptions || {};
  Storage.get({
    key: STORAGE_ACCESS_PROPERTY_DATA,
  }).then(({ value }) => {
    const accessData = value ? JSON.parse(value) : null;
    options['property_pin'] = (accessData ? accessData['pin'] : '');
    options['app_version'] = process.env.VUE_APP_VERSION;
    global.firebaseAnalytics.logEvent(fbEvent, options);
  });
};

export default {
  logEvent: logEvent,
};
