import Vue from 'vue';
import Vuex from 'vuex';
import locale from '@/store/locale';
import reservation from '@/store/reservation';
import auth from '@/store/auth';
import property from '@/store/property';
import flashMessage from '@/store/flashMessage';
import confirmDialog from '@/store/confirmDialog';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    locale,
    reservation,
    auth,
    property,
    flashMessage,
    confirmDialog,
  },
});
