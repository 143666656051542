<template>
  <div class="confirm-dialog">
    <div class="confirm-dialog__content">
      <p v-html="message"/>

      <div class="buttons">
        <ButtonInput v-if="isNormalModal" @click="successClose">{{ $t('dialog.action.ok') }}</ButtonInput>
        <ButtonInput v-if="!isNormalModal" @click="successClose">{{ $t('dialog.action.confirm') }}</ButtonInput>
        <ButtonInput v-if="!isNormalModal" @click="dismissClose" view="primary-outline-classic">{{ $t('dialog.action.cancel') }}</ButtonInput>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonInput from '@/components/Form/Input/ButtonInput';

export default {
  name: 'Dialog',
  components: {
    ButtonInput,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    successCallback: {
      type: Function,
      required: true,
    },
    dismissCallback: {
      type: Function,
      required: false,
      default: null,
    },
    isNormalModal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    successClose() {
      this.successCallback();
      this.$emit('close');
    },
    dismissClose() {
      if (this.dismissCallback) {
        this.dismissCallback();
      }
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
  .confirm-dialog {
    padding: 1rem 2rem;
    margin-bottom: 1rem;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background: $white-color;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
    @include border-radius(12px);
    @include box-shadow(.1875rem, .1875rem, .25rem, 0, rgba(0,0,0,.15));

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem 0;

      .button {
        margin: 0 1rem;
      }
    }

    .checkout-modal-content {
      text-align: center;
      margin-bottom: 2rem;
    }

    p {
      max-width: 48rem;
    }
  }
</style>
