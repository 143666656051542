<template>
  <div id="app" :class="{'without-panel': withoutPanel}">
    <FlashMessages />
    <ConfirmDialogs />
    <div v-if="!withoutPanel" class="app-panel-background" />
    <router-view/>

    <div v-if="helpText" class="help-icon" @click="logEvent('helpIconClicked') & openHelpDialog()">
      <HelpSvg />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import HelpSvg from '@/assets/images/icons/Help.svg';
import { defineColorStyles } from '@/plugins/colorSet';
import FlashMessages from '@/components/FlashMessages/index';
import ConfirmDialogs from '@/components/ConfirmDialogs/index';
import propertyDataRefresher from '@/helpers/propertyDataRefresher';
import { checkVersion } from '@/helpers/versionChecker';
import { kioskManagerHearthbeat, kioskManagerMessage } from '@/helpers/kioskManagementCentrum';
import { networkStatusChecker } from '@/helpers/network';
import { logEvent } from '@/helpers/analytics';
import confirmDialog from '@/helpers/confirmDialog';
import { CLOSE_DIALOG_ACTION } from '@/store/confirmDialog';
import {
  IS_IDLE_MODAL_VISIBLE,
  UPDATE_RESERVATION,
  SET_IDLE_LOGOUT_MODAL,
} from '@/store/reservation';
import { FALLBACK_LANGUAGE, ISO_CODE_TO_LANGUAGE } from '@/constants/trans';
import { mapGetters } from 'vuex';
import { GET_PROPERTY_DETAIL } from '@/store/property';
import { setIdleLogoutTimeout } from '@/middleware/idleLogout';

export default {
  name: 'App',
  components: {
    FlashMessages,
    ConfirmDialogs,
    HelpSvg,
  },
  mounted() {
    defineColorStyles();

    propertyDataRefresher(this);

    setInterval(() => {
      this.versionChecker();
    }, parseInt(process.env.VUE_APP_VERSION_CHECK_INTERVAL, 10) * 60 * 1000);
    this.versionChecker();

    setInterval(() => {
      kioskManagerHearthbeat();
    }, parseInt(process.env.VUE_APP_KIOSK_MANAGER_HEARTHBEAT_INTERVAL, 10) * 60 * 1000);

    networkStatusChecker(this.redirectConnected, this.redirectDisconnected, this);

    this.$el.addEventListener('click', () => this.dontLogoutAutomatically(true));
  },
  beforeDestroy: function () {
    this.$el.removeEventListener('click', () => this.dontLogoutAutomatically(true));
  },
  data() {
    return {
      disconnectedTimeout: null,
      autoIdleLogoutTimeout: null,
    };
  },
  computed: {
    ...mapGetters({
      property: GET_PROPERTY_DETAIL,
      idleModalVisible: IS_IDLE_MODAL_VISIBLE,
    }),
    withoutPanel() {
      return this.$route.name === 'home' || this.$route.name === 'login';
    },
    helpText() {
      const fallback = _.get(this.property, `settings.help.${ISO_CODE_TO_LANGUAGE[FALLBACK_LANGUAGE]}`);
      return _.get(this.property, `settings.help.${ISO_CODE_TO_LANGUAGE[this.$i18n.locale] || this.$i18n.locale}`) || fallback;
    },
  },
  watch: {
    idleModalVisible(newValue, prevValue) {
      if (prevValue === false && newValue === true) {
        this.openIdleLogoutDialog();
      }
    },
  },
  methods: {
    logEvent,
    closeDialog() {
      this.$store.dispatch(CLOSE_DIALOG_ACTION);
    },
    openHelpDialog() {
      const el = `<div class="checkout-modal-content">
          <!-- <p>
            <strong>${ this.$t('app.modal.title') }</strong>
          </p> -->
          <p>
            ${ this.helpText }
          </p>
        </div>`;
      confirmDialog(this.$store, el, () => this.closeDialog(), null, true);
    },
    closeLogoutDialogAndLogout() {
      if (this.autoIdleLogoutTimeout) {
        clearTimeout(this.autoIdleLogoutTimeout);
      }
      this.$store.dispatch(CLOSE_DIALOG_ACTION);
      this.$store.dispatch(SET_IDLE_LOGOUT_MODAL, false);
      return this.$store.dispatch(UPDATE_RESERVATION, {})
        .then(() => {
          this.$router.push({name: 'home'});
        })
        .catch(() => {
          this.$router.push({name: 'home'});
        });
    },
    dontLogoutAutomatically(onyClearIfPossible) {
      if (this.$route.name === 'checkout' && onyClearIfPossible) {
        return;
      }

      if (this.autoIdleLogoutTimeout) {
        clearTimeout(this.autoIdleLogoutTimeout);
      }
      this.$store.dispatch(SET_IDLE_LOGOUT_MODAL, false);
      setIdleLogoutTimeout(this.$store, onyClearIfPossible);
    },
    openIdleLogoutDialog() {
      if (this.autoIdleLogoutTimeout) {
        clearTimeout(this.autoIdleLogoutTimeout);
      }
      this.autoIdleLogoutTimeout = setTimeout(() => this.closeLogoutDialogAndLogout(), 20 * 1000);
      const el = `<div class="checkout-modal-content">
          <p>
            ${ this.$t('app.modal.idle_logout_content') }
          </p>
        </div>`;
      confirmDialog(this.$store, el, () => this.closeLogoutDialogAndLogout(), () => this.dontLogoutAutomatically());
    },
    versionChecker() {
      return checkVersion(this)
        .then((checkStatus) => {
          this.manageVersionStatus(checkStatus);
        });
    },
    manageVersionStatus(checkStatus) {
      if (checkStatus) {
        if (this.$route.name === 'kiosk-version-mismatch') {
          this.$router.push({ name: 'home' });
        }

        return ;
      }

      if (this.$route.name !== 'kiosk-version-mismatch') {
        this.$router.push({ name: 'kiosk-version-mismatch' });
      }
    },
    redirectConnected() {
      if (this.disconnectedTimeout) {
        clearTimeout(this.disconnectedTimeout);
      }
      if (this.$route.name === 'no-internet') {
        this.$router.push({ name: 'home' });
      }
    },
    redirectDisconnected() {
      if (this.$route.name !== 'no-internet') {
        this.disconnectedTimeout = setTimeout(() => {
          kioskManagerMessage('NO-INTERNET');
          this.$router.push({ name: 'no-internet' });
        }, 20 * 1000);
      }
    },
  },
};
</script>

<style lang="scss">
  * {
    box-sizing: border-box;
  }

  body,
  html {
    background: $body-bg;
    color: $text-color;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: $font-family-content;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media (min-height: 550px) {
      font-size: 18px;
    }

    @media (min-height: 650px) {
      font-size: 20px;
    }

    @media (min-height: 760px) {
      font-size: 24px;
    }

    @media (min-height: 1000px) {
      font-size: 30px;
    }
  }

  #app {
    height: calc(100vh - 5rem);
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.without-panel {
      height: 100vh;
      margin-top: 0;
    }
  }

  .share-or-separator {
    position: relative;
    margin: 2rem 0 1.5rem;

    &:before {
      content: ' ';
      width: calc(100% - 2rem);
      height: 1px;
      display: block;
      background: rgba(0, 0, 0, .1);
      margin: 1.5rem auto;
      margin: auto;
    }

    > span {
      width: 100%;
      font-size: .875rem;
      position: absolute;
      top: -10px;
      display: block;
      text-align: center;

      > span {
        background: #eaf6fd;
        padding: 0 1rem;
      }
    }
  }

  .app-panel-background {
    width: 100%;
    height: 5rem;
    background: $body-bg;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .help-icon {
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
</style>
