import Vue from 'vue';
import {
  ENDPOINT_CHECKOUT,
} from '@/api/handler.endpoints';
import {
  POST_ROOM_CHECKOUT,
} from '@/api/handler.methods';

export const roomCheckout = (reservationId, roomId) => {
  return Vue.axios.post(ENDPOINT_CHECKOUT, {
    reservation_id: reservationId,
    room_id: roomId,
  })
    .then(({data}) => data.responseBody || [])
    .catch((error) => {
      throw error;
    });
};

export default {
  [POST_ROOM_CHECKOUT]: roomCheckout,
};

