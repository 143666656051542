import Vue from 'vue';
import {
  ENDPOINT_REFRESH_PROPERTY_DATA,
} from '@/api/handler.endpoints';
import {
  REFRESH_PROPERTY_DATA,
} from '@/api/handler.methods';

export const refreshPropertyData = () => {
  return Vue.axios.get(ENDPOINT_REFRESH_PROPERTY_DATA)
    .then(({data}) => data.responseBody || [])
    .catch((error) => {
      throw error;
    });
};

export default {
  [REFRESH_PROPERTY_DATA]: refreshPropertyData,
};
