<template>
  <a href=""
    @click.prevent="$emit('click', iso)"
    :data-iso="iso"
    :data-flag="flagComponent"
    :class="{
      'language-item': true,
      'language-item-selected': selectedIso === 'en',
    }"
  >
    <component v-bind:is="flagComponent" />
  </a>
</template>

<script>
import FlagEnglish from '@/assets/images/flags/flagEnglish.svg';
import FlagCzech from '@/assets/images/flags/flagCzech.svg';
import FlagFrance from '@/assets/images/flags/flagFrance.svg';
import FlagGerman from '@/assets/images/flags/flagGerman.svg';
import FlagItaly from '@/assets/images/flags/flagItaly.svg';
import FlagRussia from '@/assets/images/flags/flagRussia.svg';
import FlagSlovakia from '@/assets/images/flags/flagSlovakia.svg';
import FlagSpain from '@/assets/images/flags/flagSpain.svg';

export default {
  name: 'Language',
  components: {
    FlagEnglish,
    FlagCzech,
    FlagFrance,
    FlagGerman,
    FlagItaly,
    FlagRussia,
    FlagSlovakia,
    FlagSpain,
  },
  props: {
    iso: {
      type: String,
      required: true,
    },
    selectedIso: {
      type: String,
      required: true,
    },
  },
  computed: {
    flagComponent() {
      switch (this.iso) {
      case 'en':
        return FlagEnglish;
      case 'cs':
        return FlagCzech;
      case 'de':
        return FlagGerman;
      case 'it':
        return FlagItaly;
      case 'es':
        return FlagSpain;
      case 'sk':
        return FlagSlovakia;
      case 'fr':
        return FlagFrance;
      case 'ru':
        return FlagRussia;
      }
      return null;
    },
  },
};
</script>