import _ from 'lodash';

export const GET_RESERVATION_DETAIL = 'GET_RESERVATION_DETAIL';
export const GET_ROOMS = 'GET_ROOMS';
export const GET_ROOM_BY_ID = 'GET_ROOM_BY_ID';
export const GET_GUEST = 'GET_GUEST';
export const GET_CHECKINS = 'GET_CHECKINS';
export const GET_TTLOCK_DATA = 'GET_TTLOCK_DATA';
export const IS_IDLE_MODAL_VISIBLE = 'IS_IDLE_MODAL_VISIBLE';

export const SET_RESERVATION = 'SET_RESERVATION';
export const ADD_DISPERSED_CARD_PROPER = 'ADD_DISPERSED_CARD_PROPER';
export const ADD_DISPERSED_CARD_SPARE = 'ADD_DISPERSED_CARD_SPARE';
export const SET_TTLOCK_DATA = 'SET_TTLOCK_DATA';
export const IDLE_LOGOUT_MODAL = 'IDLE_LOGOUT_MODAL';

export const UPDATE_RESERVATION = 'UPDATE_RESERVATION';
export const DISPATCH_CARD_PROPER = 'DISPATCH_CARD_PROPER';
export const DISPATCH_CARD_SPARE = 'DISPATCH_CARD_SPARE';
export const SAVE_TTLOCK_DATA = 'SAVE_TTLOCK_DATA';
export const SET_IDLE_LOGOUT_MODAL = 'SET_IDLE_LOGOUT_MODAL';

const state = {
  data: {},
  ttLockData: {},
  idleLogoutModal: false,
};

const getters = {
  [GET_RESERVATION_DETAIL]: (state) =>
    _.get(state.data, 'reservation') || null,

  [GET_ROOMS]: (state) =>
    _.get(state.data, 'rooms') || [],

  [GET_ROOM_BY_ID]: (state, getters) => (id) =>
    _.find(getters[GET_ROOMS], {id: id}) || null,

  [GET_CHECKINS]: (state) =>
    _.get(state.data, 'checkins') || [],

  [GET_TTLOCK_DATA]: (state) =>
    state.ttLockData || {},

  [IS_IDLE_MODAL_VISIBLE]: (state) =>
    state.idleLogoutModal || false,
};

const mutations = {
  [SET_RESERVATION](state, reservationData) {
    state.data = reservationData;
  },
  [ADD_DISPERSED_CARD_PROPER](state, roomId) {
    const roomIndex = _.findIndex(state.data.rooms, {id: roomId});
    state.data.rooms[roomIndex].dispatched_cards += 1;
  },
  [ADD_DISPERSED_CARD_SPARE](state, roomId) {
    const roomIndex = _.findIndex(state.data.rooms, {id: roomId});
    state.data.rooms[roomIndex].spare_cards += 1;
  },
  [SET_TTLOCK_DATA](state, data) {
    state.ttLockData = data;
  },
  [IDLE_LOGOUT_MODAL](state, visible) {
    state.idleLogoutModal = !!visible;
  },
};

const actions = {
  [UPDATE_RESERVATION]({ commit }, reservationData) {
    commit(SET_RESERVATION, reservationData);
    commit(SET_TTLOCK_DATA, {});
  },
  [DISPATCH_CARD_PROPER]({ commit }, roomId) {
    commit(ADD_DISPERSED_CARD_PROPER, roomId);
  },
  [DISPATCH_CARD_SPARE]({ commit }, roomId) {
    commit(ADD_DISPERSED_CARD_SPARE, roomId);
  },
  [SAVE_TTLOCK_DATA]({ commit }, data) {
    commit(SET_TTLOCK_DATA, data);
  },
  [SET_IDLE_LOGOUT_MODAL]({ commit }, visible) {
    commit(IDLE_LOGOUT_MODAL, visible);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};