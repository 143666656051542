import _ from 'lodash';

export const GET_PROPERTY_DETAIL = 'GET_PROPERTY_DETAIL';
export const GET_CHECKIN_SETTINGS = 'GET_CHECKIN_SETTINGS';
export const GET_CHECKIN_INPUTS = 'GET_CHECKIN_INPUTS';
export const GET_CHECKIN_VISA_REQUIREMENTS = 'GET_CHECKIN_VISA_REQUIREMENTS';
export const GET_CHECKIN_PURPOSES_OF_STAY = 'GET_CHECKIN_PURPOSES_OF_STAY';
export const GET_CHECKIN_TERMS_AND_CONDITIONS = 'GET_CHECKIN_TERMS_AND_CONDITIONS';

export const SET_PROPERTY_DATA = 'SET_PROPERTY_DATA';

export const UPDATE_PROPERTY_DATA = 'UPDATE_PROPERTY_DATA';

const state = {
  detail: {},
};

const getters = {
  [GET_PROPERTY_DETAIL](state) {
    return state.detail;
  },
  [GET_CHECKIN_SETTINGS](state) {
    return _.get(state.detail, 'settings.checkInSettings');
  },
  [GET_CHECKIN_INPUTS](state, getters) {
    return getters[GET_CHECKIN_SETTINGS]['inputs'] || false;
  },
  [GET_CHECKIN_VISA_REQUIREMENTS](state, getters) {
    return getters[GET_CHECKIN_SETTINGS]['visaRequirements'] || [];
  },
  [GET_CHECKIN_PURPOSES_OF_STAY](state, getters) {
    return getters[GET_CHECKIN_SETTINGS]['purposesOfStay'] || [];
  },
  [GET_CHECKIN_TERMS_AND_CONDITIONS](state, getters) {
    return getters[GET_CHECKIN_SETTINGS]['termsAndConditions'] || [];
  },
};

const mutations = {
  [SET_PROPERTY_DATA](state, detail) {
    state.detail = detail;
  },
};

const actions = {
  [UPDATE_PROPERTY_DATA]({ commit }, data) {
    commit(SET_PROPERTY_DATA, data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
