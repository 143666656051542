export const DEFAULT_LANGUAGE = 'en';
export const FALLBACK_LANGUAGE = 'en';
// export const SUPPORTED_LANGUAGES = ['en', 'it', 'es', 'de', 'fr', 'cs', 'sk', 'ru'];
export const SUPPORTED_LANGUAGES = ['en', 'cs', 'sk'];

export const ISO_CODE_TO_LANGUAGE = {
  'en': 'en_GB',
  'cs': 'cs_CZ',
  'es': 'es-ES',
  'de': 'de_DE',
  'fr': 'fr_FR',
  'sk': 'sk_SK',
  'ru': 'ru_RU',
  'it': 'it_IT',
};
