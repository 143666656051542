var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_c('MyStayLogo',{staticClass:"mystay-logo"}),_c('h2',[_vm._v(_vm._s(_vm.$t('login.title')))]),_c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"login-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"form-input-block form-input-block--wide"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Input',{attrs:{"name":"email","error":_vm.alreadySent ? errors[0] : null,"placeholder":_vm.$t('login.form.email.placeholder')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('div',{staticClass:"form-input-block form-input-block--wide"},[_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Input',{attrs:{"name":"password","type":"password","error":_vm.alreadySent ? errors[0] : null,"placeholder":_vm.$t('login.form.password.placeholder')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('div',{staticClass:"form-input-block form-input-block--wide form-input-block-last"},[_c('ValidationProvider',{attrs:{"name":"propertyPin","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Input',{attrs:{"name":"propertyPin","error":_vm.alreadySent ? errors[0] : null,"placeholder":_vm.$t('login.form.property_pin.placeholder'),"minlength":"5"},model:{value:(_vm.propertyPin),callback:function ($$v) {_vm.propertyPin=$$v},expression:"propertyPin"}})]}}],null,true)})],1),_c('ButtonInput',{attrs:{"type":"submit","disabled":invalid,"loading":_vm.formSending}},[_vm._v(" "+_vm._s(_vm.$t('login.form.submit_button'))+" ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }