import Vue from 'vue';
import reservation from './endpoints/reservation';
import auth from './endpoints/auth';
import dispense from './endpoints/dispense';
import checkout from './endpoints/checkout';
import roomDetails from './endpoints/roomDetails';
import guestAuth from './endpoints/guestAuth';
import kioskStatus from './endpoints/kioskStatus';
import refreshData from './endpoints/refreshData';
import checkIn from './endpoints/checkin';
import ttLock from './endpoints/ttLock';
import moment from 'moment';
import 'moment-timezone';
import * as endpoints from '@/api/handler.endpoints';
import {
  GET_DEVICE_INFO,
  GET_ACCESS_TOKEN,
} from '@/store/auth';
import {
  GET_LOCALE,
} from '@/store/locale';
import hmacSHA256 from 'crypto-js/hmac-sha256';

const actions = {
  [endpoints.ENDPOINT_RESERVATION]: reservation,
  [endpoints.ENDPOINT_AUTH]: auth,
  [endpoints.ENDPOINT_DISPENSE]: dispense,
  [endpoints.ENDPOINT_CHECKOUT]: checkout,
  [endpoints.ENDPOINT_ROOM_DETAILS]: roomDetails,
  [endpoints.ENDPOINT_GUEST_AUTHORIZE]: guestAuth,
  [endpoints.ENDPOINT_KIOSK_STATUS]: kioskStatus,
  [endpoints.ENDPOINT_REFRESH_PROPERTY_DATA]: refreshData,
  [endpoints.ENDPOINT_CHECKIN]: checkIn,
  [endpoints.ENDPOINT_TTLOCK_ISSUE]: ttLock,
};

const request = (vm, type, action) => {
  const timestamp = moment.tz('Europe/Prague').unix();
  Vue.axios.defaults.headers['Access-Token'] = calculateCommunicationAccessToken(vm, timestamp);
  Vue.axios.defaults.headers['Accept-Language'] = vm.$store.getters[GET_LOCALE];
  Vue.axios.defaults.headers['Date-Timestamp'] = timestamp;
  Vue.axios.defaults.headers['Version'] = process.env.VUE_APP_VERSION;
  return actions[type][action];
};

const calculateCommunicationAccessToken = (vm, timestamp) => {
  const deviceInfo = vm.$store.getters[GET_DEVICE_INFO];
  const accessToken = vm.$store.getters[GET_ACCESS_TOKEN];
  if (!accessToken) {
    return null;
  }
  return hmacSHA256(deviceInfo.uuid + '|' + timestamp, accessToken).toString();
};

export default request;
