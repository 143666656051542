export const ENDPOINT_AUTH = '/login';
export const ENDPOINT_RESERVATION = '/reservation';
export const ENDPOINT_DISPENSE = '/dispense';
export const ENDPOINT_CHECKOUT = '/checkout';
export const ENDPOINT_ROOM_DETAILS = '/room-details';
export const ENDPOINT_GUEST_AUTHORIZE = '/authorize';
export const ENDPOINT_KIOSK_STATUS = '/kiosk-status';
export const ENDPOINT_REFRESH_PROPERTY_DATA = '/refresh-data';
export const ENDPOINT_CHECKIN = '/checkin';
export const ENDPOINT_TTLOCK_ISSUE = '/ttlock/issue';
