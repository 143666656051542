var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"language-select",class:{
    'language-select-absolute': _vm.absolutePosition,
  }},[(_vm.absolutePosition)?_c('div',{staticClass:"selected-flag"},[_c('language',{attrs:{"iso":_vm.selectedIso,"selectedIso":_vm.selectedIso},on:{"click":_vm.openFlagList}})],1):_vm._e(),_c('div',{class:{
      'flag-list': true,
      'visible': _vm.absoluteIsoSelection || !_vm.absolutePosition,
    },style:(_vm.absolutePosition ? {
      marginRight: '4rem',
      width: (4 * _vm.languages.length) + 'rem',
      display: _vm.absoluteIsoSelection ? null : 'none',
    } : {})},_vm._l((_vm.languages),function(languageIso){return _c('language',{key:languageIso,attrs:{"iso":languageIso,"selectedIso":_vm.selectedIso},on:{"click":_vm.changeLang}})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }