import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { i18n } from '@/plugins/i18n';
import { configure } from 'vee-validate';
import moment from 'moment';

configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validations.messages.${values._rule_}`, values);
  },
});

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

// Add a rule.
extend('date', {
  validate: (value) => {
    const date = new moment(value);
    return date.isValid();
  },
  message: i18n.t('validations.messages.invalid_date'),
});

// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
