import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from '@/plugins/i18n';
import { Trans } from '@/plugins/Translation';
import { Plugins } from '@capacitor/core';
import '@/plugins/veeValidate';
import '@/plugins/vSelect';
import '@/plugins/vueTelInput';
import {
  CHANGE_DEVICE,
  CHANGE_ACCESS_DATA,
} from '@/store/auth';
import { STORAGE_ACCESS_PROPERTY_DATA } from '@/constants/storage';
import {
  startManagementCentrumWebsocketClient,
  kioskManagerHearthbeat,
} from '@/helpers/kioskManagementCentrum';
import firebase from 'firebase';
// import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.VUE_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_GMEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
global.firebaseAnalytics = firebase.analytics();

/*
FirebaseAnalytics.initializeFirebase(firebaseConfig);
global.firebaseAnalytics = FirebaseAnalytics.setCollectionEnabled({
  enabled: false
});
*/

require('dotenv').config();

startManagementCentrumWebsocketClient()
  .then((connection) => {
    global.managementCentrumClient = connection;
    kioskManagerHearthbeat();
  });

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans);

Vue.config.productionTip = false;

import axios from '@/plugins/axios';

const { Device } = Plugins;
Device.getInfo()
  .then((deviceData) => {
    axios.defaults.headers.Device = deviceData.uuid;
    store.dispatch(CHANGE_DEVICE, deviceData);
  });

const { Storage } = Plugins;
Storage.get({
  key: STORAGE_ACCESS_PROPERTY_DATA,
})
  .then((accessData) => {
    if (accessData && accessData.value && accessData.value !== 'null') {
      store.dispatch(CHANGE_ACCESS_DATA, JSON.parse(accessData.value));
    }
  });

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
