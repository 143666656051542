<template>
  <div class="login">
    <MyStayLogo class="mystay-logo" />
    <h2>{{ $t('login.title') }}</h2>

    <div>
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="onSubmit" class="login-form">
          <div class="form-input-block form-input-block--wide">
            <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
              <Input v-model="email"
                name="email"
                :error="alreadySent ? errors[0] : null"
                :placeholder="$t('login.form.email.placeholder')"
              />
            </ValidationProvider>
          </div>

          <div class="form-input-block form-input-block--wide">
            <ValidationProvider name="password" rules="required" v-slot="{ errors }">
              <Input v-model="password"
                name="password"
                type="password"
                :error="alreadySent ? errors[0] : null"
                :placeholder="$t('login.form.password.placeholder')"
              />
            </ValidationProvider>
          </div>

          <div class="form-input-block form-input-block--wide form-input-block-last">
            <ValidationProvider name="propertyPin" rules="required|min:5" v-slot="{ errors }">
              <Input v-model="propertyPin"
                name="propertyPin"
                :error="alreadySent ? errors[0] : null"
                :placeholder="$t('login.form.property_pin.placeholder')"
                minlength="5"
              />
            </ValidationProvider>
          </div>

          <ButtonInput type="submit" :disabled="invalid" :loading="formSending">
            {{ $t('login.form.submit_button') }}
          </ButtonInput>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
import Input from '@/components/Form/Input/Input';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import { STORAGE_ACCESS_PROPERTY_DATA } from '@/constants/storage';
import { CHANGE_ACCESS_DATA } from '@/store/auth';
import * as endpoints from '@/api/handler.endpoints';
import * as methods from '@/api/handler.methods';
import api from '@/api/handler';
import MyStayLogo from '@/assets/images/myStayLogo.svg';
import flashMessage from '@/helpers/flashMessage';
// import errorHandler from '@/api/errorHandler';
import { logEvent } from '@/helpers/analytics';

export default {
  name: 'Login',
  components: {
    Input,
    ButtonInput,
    MyStayLogo,
  },
  async mounted() {
    /* Storage.set({
      key: STORAGE_ACCESS_PROPERTY_DATA,
      value: null,
    }); */
    const accessData = await Storage.get({
      key: STORAGE_ACCESS_PROPERTY_DATA,
    });
    if (accessData && accessData.value && accessData.value !== 'null') {
      await this.$store.dispatch(CHANGE_ACCESS_DATA, JSON.parse(accessData.value))
        .then(() => {
          this.$router.push('/home');
        });
    }
  },
  data() {
    return {
      email: null,
      password: null,
      propertyPin: null,
      alreadySent: false,
      formSending: false,
    };
  },
  methods: {
    onSubmit () {
      this.alreadySent = true;
      this.getAccessToken();
    },
    getAccessToken() {
      this.formSending = true;
      api(this, endpoints.ENDPOINT_AUTH, methods.LOGIN)(this.email, this.password, this.propertyPin)
        .then(({data}) => {
          if (!data.kiosk_enabled) {
            flashMessage(this.$store, 'Kiosk is not enabled in MyStay', 'danger');
            this.formSending = false;
            return;
          }

          logEvent('onLoginLoginClicked');

          data.pin = this.propertyPin;

          return this.$store.dispatch(CHANGE_ACCESS_DATA, data)
            .then(() => {
              Storage.set({
                key: STORAGE_ACCESS_PROPERTY_DATA,
                value: JSON.stringify(data),
              });
              this.$router.push('/home');
            });
        })
        .catch(() => {
          this.formSending = false;
          flashMessage(this.$store, 'Invalid credentials', 'danger');
        });
    },
  },
};
</script>

<style lang="scss">
  .login {
    text-align: center;

    .mystay-logo {
      width: 4rem;
      height: 4rem;
    }
  }
</style>
