import api from '@/api/handler';
import * as endpoints from '@/api/handler.endpoints';
import * as methods from '@/api/handler.methods';

export const checkVersion = (vm) => {
  return api(vm, endpoints.ENDPOINT_KIOSK_STATUS, methods.KIOSK_STATUS)()
    .then((data) => {
      if (data.metadata.success) {
        const endpointCurrentVersion = versionMatchSum(data.data);
        const appCurrentVersion = versionMatchSum(process.env.VUE_APP_VERSION);

        if (endpointCurrentVersion > appCurrentVersion) {
          return false;
        }
      }
      return true;
    })
    .catch((err) => {
      console.log('Error while getting version check status', err);
      return true;
    });
};

const versionMatchSum = (versionString) => {
  const versionStringParts = versionString.split('.');
  return parseInt(versionStringParts[0], 10) * 100 + parseInt(versionStringParts[1], 10) || 0;
};
