<template>
  <div class="home">
    <span class="pre-text">{{ $t('home.property.pre_title') }}</span>
    <h1 v-if="property.name === 'Archibald At the Charles Bridge'" class="property-name main-title main-title-archibald">
      <strong>Archibald</strong> At the Charles Bridge
    </h1>
    <h1 v-else class="property-name main-title">
      {{ property.name }}
    </h1>

    <h2>{{ $t('home.language_select') }}</h2>
    <LanguageSelect />

    <h2>{{ $t('home.actions.title') }}</h2>

    <div class="form-input-block form-input-block-full-width form-input-block-last">
      <ButtonInput type="button"
        size="large"
        @click="logEvent('onHomepageCheckinClicked') & $router.push(`/reservation/${actions.actionCheckIn}`)"
      >
        {{ $t('home.actions.button.checkin') }}
      </ButtonInput>
    </div>

        <div class="form-input-block form-input-block-full-width">
      <ButtonInput type="button"
        size="large"
        @click="logEvent('onHomepageCheckOutClicked') & $router.push(`/reservation/${actions.actionCheckOut}`)"
      >
        {{ $t('home.actions.button.checkout') }}
      </ButtonInput>
    </div>

    <div class="form-input-block form-input-block-full-width form-input-block-last">
      <ButtonInput type="button"
        size="large"
        @click="logEvent('onHomepageLostCardClicked') & $router.push(`/reservation/${actions.actionLostCard}`)"
      >
        {{ $t('home.actions.button.lost_card') }}
      </ButtonInput>
    </div>
  </div>
</template>

<script>
import LanguageSelect from '@/components/LanguageSelect/index';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import {
  RESERVATION_ACTION_CHECKIN,
  RESERVATION_ACTION_LOST_CARD,
  RESERVATION_ACTION_CHECKOUT,
} from '@/constants/action.type';
import { mapGetters } from 'vuex';
import {
  GET_PROPERTY_DETAIL,
} from '@/store/property';
import { logEvent } from '@/helpers/analytics';

export default {
  name: 'Home',
  components: {
    LanguageSelect,
    ButtonInput,
  },
  data() {
    return {
      actions: {
        actionCheckIn: RESERVATION_ACTION_CHECKIN,
        actionLostCard: RESERVATION_ACTION_LOST_CARD,
        actionCheckOut: RESERVATION_ACTION_CHECKOUT,
      },
    };
  },
  computed: {
    ...mapGetters({
      property: GET_PROPERTY_DETAIL,
    }),
  },
  methods: {
    logEvent,
  },
};
</script>

<style lang="scss">
  .home {
    text-align: center;
  }

  .pre-text {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1rem;
    color: $primary-color;
  }

  .property-name {
    margin: 0;
  }
</style>
