import { SET_IDLE_LOGOUT_MODAL } from '../store/reservation';

const logoutTime = 2 * 60 * 1000; // 2 minutes
let logoutTimeout = null;
global.logoutTimeout = logoutTimeout;

export function idleLogout({ router, next }) {
  if (global.logoutTimeout) {
    clearTimeout(global.logoutTimeout);
  }
  setTimeout(() => setIdleLogoutTimeout(router.app.$store), 1000);
  return next();
}

export function setIdleLogoutTimeout($store, onlyClearIfPossible) {
  if (onlyClearIfPossible) {
    if (global.logoutTimeout) {
      clearTimeout(global.logoutTimeout);

      global.logoutTimeout = setTimeout(() => {
        openIdleLogoutDialog($store);
      }, logoutTime);
    }
    return;
  }

  if (global.logoutTimeout) {
    clearTimeout(global.logoutTimeout);
  }
  global.logoutTimeout = setTimeout(() => {
    openIdleLogoutDialog($store);
  }, logoutTime);
}

export function idleLogoutClean({ next }) {
  if (global.logoutTimeout) {
    clearTimeout(global.logoutTimeout);
    global.logoutTimeout = null;
  }
  return next();
}

const openIdleLogoutDialog = ($store) => {
  $store.dispatch(SET_IDLE_LOGOUT_MODAL, true);
};
