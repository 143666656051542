import { DEFAULT_LANGUAGE } from '@/constants/trans';

export const GET_LOCALE = 'GET_LOCALE';
export const SET_LOCALE = 'SET_LOCALE';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

const state = {
  locale: DEFAULT_LANGUAGE,
};

const getters = {
  [GET_LOCALE]: (state) =>
    state.locale,
};

const mutations = {
  [SET_LOCALE](state, locale) {
    state.locale = locale;
  },
};

const actions = {
  [CHANGE_LOCALE]({ commit }, locale) {
    commit(SET_LOCALE, locale);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
