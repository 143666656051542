<template>
  <div class="language-select"
    :class="{
      'language-select-absolute': absolutePosition,
    }"
  >
    <div class="selected-flag" v-if="absolutePosition">
      <language :iso="selectedIso" @click="openFlagList" :selectedIso="selectedIso" />
    </div>

    <div :class="{
        'flag-list': true,
        'visible': absoluteIsoSelection || !absolutePosition,
      }"
      :style="absolutePosition ? {
        marginRight: '4rem',
        width: (4 * languages.length) + 'rem',
        display: absoluteIsoSelection ? null : 'none',
      } : {}"
    >
      <language v-for="languageIso in languages"
        :key="languageIso"
        :iso="languageIso"
        :selectedIso="selectedIso"
        @click="changeLang"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Language from './Language';
import { localeChanged } from 'vee-validate';
import { CHANGE_LOCALE } from '@/store/locale';
import { SUPPORTED_LANGUAGES } from '@/constants/trans';

export default {
  name: 'LanguageSelect',
  components: {
    Language,
  },
  data() {
    return {
      absoluteIsoSelection: false,
      absoluteIsoSelectionAnimation: false,
    };
  },
  props: {
    absolutePosition: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedIso() {
      return this.$i18n.locale;
    },
    languages() {
      if (!this.absolutePosition) {
        return SUPPORTED_LANGUAGES;
      }
      return _.filter(SUPPORTED_LANGUAGES, (langIso) => langIso !== this.selectedIso);
    },
  },
  methods: {
    openFlagList() {
      if (!this.absoluteIsoSelection) {
        this.absoluteIsoSelection = true;
        setTimeout(() => {
          this.absoluteIsoSelectionAnimation = true;
        }, 10);
        return;
      }
      this.absoluteIsoSelection = false;
      this.absoluteIsoSelectionAnimation = false;
    },
    changeLang(iso) {
      this.absoluteIsoSelection = false;
      this.absoluteIsoSelectionAnimation = false;
      this.$store.dispatch(CHANGE_LOCALE, iso);
      this.$i18n.locale = iso;
      localeChanged();
    },
  },
};
</script>

<style lang="scss">
  .language-select {
    display: flex;
    margin-bottom: 2rem;
    justify-content: center;

    a {
      margin: 0 .5rem;

      &:focus,
      &:focus-visible {
        outline: none;
      }

      svg {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
      }
    }
  }

  .language-select-absolute {
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 2;

    .flag-list {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      margin-right: -8rem;
      transition: margin-right .3s ease-in-out;

      &.visible {
        opacity: 1;
      }
    }
  }

  .selected-flag {
    z-index: 3;
  }
</style>
