import { Plugins } from '@capacitor/core';
const { Network } = Plugins;

export const networkStatusChecker = (redirectConnectedCallback, redirectDisconnectedCallback) => {
  Network.getStatus()
    .then((status) => {
      manageStatus(status, redirectConnectedCallback, redirectDisconnectedCallback);
    })
    .catch((e) => {
      console.log('Error getting network status', e);
    });

  Network.addListener('networkStatusChange', (status) => {
    manageStatus(status, redirectConnectedCallback, redirectDisconnectedCallback);
  });
};

const manageStatus = (status, redirectConnectedCallback, redirectDisconnectedCallback) => {
  if (status.connected) {
    redirectConnectedCallback();
  } else if (!status.connected) {
    redirectDisconnectedCallback();
  }
};