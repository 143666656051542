import Vue from 'vue';
import {
  ENDPOINT_DISPENSE,
} from '@/api/handler.endpoints';
import {
  POST_DISPENSE_CARD,
} from '@/api/handler.methods';

export const TYPE_PROPER_CARD = 'proper';
export const TYPE_SPARE_CARD = 'spare';

export const dispensedRoomCard = (type, roomId, reservationId) => {
  return Vue.axios.post(ENDPOINT_DISPENSE, {
    type,
    room_id: roomId,
    reservation_id: reservationId,
  })
    .then(({data}) => data.responseBody || [])
    .catch((error) => {
      throw error;
    });
};

export default {
  [POST_DISPENSE_CARD]: dispensedRoomCard,
};

