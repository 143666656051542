import { CONFIRM_DIALOG_OPEN } from '@/store/confirmDialog';

export default ($store, message, successCallback, dismissCallback, isNormalModal) => {
  $store.dispatch(CONFIRM_DIALOG_OPEN, {
    message,
    successCallback,
    dismissCallback,
    isNormalModal,
  });
};
