import Vue from 'vue';
import {
  ENDPOINT_TTLOCK_ISSUE,
} from '@/api/handler.endpoints';
import {
  POST_TTLOCK_DATA,
} from '@/api/handler.methods';

export const getTTLockData = (reservationId, roomId, dateFrom, dateTo) => {
  return Vue.axios.post(ENDPOINT_TTLOCK_ISSUE, {
    reservation_id: reservationId,
    room_id: roomId,
    date_from: dateFrom,
    date_to: dateTo,
  })
    .then(({data}) => data.responseBody || [])
    .catch((error) => {
      throw error;
    });
};

export default {
  [POST_TTLOCK_DATA]: getTTLockData,
};
