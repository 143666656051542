import _ from 'lodash';

export const defineColorStyles = (colorSet) => {
  const colors = {
    '--main-color': _.get(colorSet, 'primaryColor', '#672ec2'),
    ...toHSL(_.get(colorSet, 'primaryColor', '#672ec2'), '--main-color'),

    '--text-color': _.get(colorSet, 'textColor', '#000000'),
    ...toHSL(_.get(colorSet, 'textColor', '#000000'), '--text-color'),

    '--secondary-text-color': _.get(colorSet, 'secondaryTextColor', '#2e2e2e'),
    ...toHSL(_.get(colorSet, 'secondaryTextColor', '#2e2e2e'), '--secondary-text-color'),

    '--info-text-color': _.get(colorSet, 'infoTextColor', '#87888a'),
    ...toHSL(_.get(colorSet, 'infoTextColor', '#87888a'), '--info-text-color'),

    '--secondary-body-bg': _.get(colorSet, 'secondaryBodyBg', '#ffffff'),
    ...toHSL(_.get(colorSet, 'secondaryBodyBg', '#ffffff'), '--secondary-body-bg'),

    '--hotel-title-color': _.get(colorSet, 'hotelTitleColor', '#ffffff'),
    '--menu-text-color': _.get(colorSet, 'menuTextColor', '#ffffff'),
    '--body-bg': _.get(colorSet, 'bodyBg', '#eaf6fd'),
    '--menu-bg': _.get(colorSet, 'menuBg', '#4a5ebe'),
  };
  _.each(colors, (color, key) => {
    document.querySelector(':root').style.setProperty(key, color);
  });
};

const toHSL = (hex, colorName) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);

  r /= 255, g /= 255, b /= 255;
  let max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
    case r:
      h = (g - b) / d + (g < b ? 6 : 0);
      break;
    case g:
      h = (b - r) / d + 2;
      break;
    case b:
      h = (r - g) / d + 4;
      break;
    }
    h /= 6;
  }

  s = s * 100;
  s = Math.round(s);
  l = l * 100;
  l = Math.round(l);
  h = Math.round(360*h);

  return {
    [colorName + '-h']: h,
    [colorName + '-s']: s + '%',
    [colorName + '-l']: l + '%',
  };
};
