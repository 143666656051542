import Vue from 'vue';
import {
  ENDPOINT_CHECKIN,
} from '@/api/handler.endpoints';
import {
  POST_CHECKIN,
} from '@/api/handler.methods';

export const createNewCheckIn = (reservationId, data) => {
  return Vue.axios.post(ENDPOINT_CHECKIN, {
    reservation_id: reservationId,
    stay_id: reservationId,
    main_guest: data,
  })
    .then(({data}) => data.responseBody || [])
    .catch((error) => {
      throw error;
    });
};

export default {
  [POST_CHECKIN]: createNewCheckIn,
};

