<template>
  <div :class="{
    'flash-message': true,
    [type]: true,
  }"
    @click="removeMessage"
    ref="message"
  >
    <p>{{ message }}</p>
    <div class="flash-message__animation">
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Message',
  props: {
    type: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    messageIndex: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.timeout = setTimeout(() => {
      this.removeMessage(this.messageIndex);
    }, 4900);
    setTimeout(() => {
      this.$refs.message.classList.add('animate');
    }, 100);
  },
  data() {
    return {
      timeout: null,
    };
  },
  methods: {
    removeMessage() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.$emit('click', this.messageIndex);
    },
  },
};
</script>

<style lang="scss">
  .flash-message {
    padding: 1rem 2rem;
    margin-bottom: 1rem;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    @include border-radius(12px);
    @include box-shadow(.1875rem, .1875rem, .25rem, 0, rgba(0,0,0,.15));

    &.animate {
      .flash-message__animation {
        span {
          width: 100%;
        }
      }
    }

    &.danger {
      color: $white-color;
      background: $red-color;

      .flash-message__animation {
        background: lighten($red-color, 10%);

        span {
          background: darken($red-color, 10%);
        }
      }
    }

    &.success {
      color: $white-color;
      background: $green-color;

      .flash-message__animation {
        background: lighten($green-color, 10%);

        span {
          background: darken($green-color, 10%);
        }
      }
    }

    &__animation {
      background: lighten($red-color, 10%);
      width: 100%;
      height: .25rem;
      position: absolute;
      bottom: 0;
      left: 0;

      span {
        width: 0;
        height: .25rem;
        display: block;
        background: darken($red-color, 10%);
        transition: width 5s ease-in-out;
      }
    }

    p {
      margin: 0;
    }
  }
</style>
