import Vue from 'vue';
import {
  ENDPOINT_KIOSK_STATUS,
} from '@/api/handler.endpoints';
import {
  KIOSK_STATUS,
} from '@/api/handler.methods';

export const kioskStatus = () => {
  return Vue.axios.get(ENDPOINT_KIOSK_STATUS)
    .then(({data}) => data.responseBody || [])
    .catch((error) => {
      throw error;
    });
};

export default {
  [KIOSK_STATUS]: kioskStatus,
};
