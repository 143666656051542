import { SET_PROPERTY_DATA } from './property';

export const GET_DEVICE_INFO = 'GET_DEVICE_INFO';
export const GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN';

export const SET_DEVICE = 'SET_DEVICE';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';

export const CHANGE_DEVICE = 'CHANGE_DEVICE';
export const CHANGE_ACCESS_DATA = 'CHANGE_ACCESS_TOKEN';

const state = {
  device: {},
  accessToken: null,
};

const getters = {
  [GET_DEVICE_INFO](state) {
    return state.device;
  },
  [GET_ACCESS_TOKEN](state) {
    return state.accessToken;
  },
};

const mutations = {
  [SET_DEVICE](state, device) {
    state.device = device;
  },
  [SET_ACCESS_TOKEN](state, accessToken) {
    state.accessToken = accessToken;
  },
};

const actions = {
  [CHANGE_DEVICE]({ commit }, device) {
    commit(SET_DEVICE, device);
  },
  [CHANGE_ACCESS_DATA]({ commit }, data) {
    commit(SET_ACCESS_TOKEN, data.access_token);
    commit(SET_PROPERTY_DATA, data, {root: true});
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
