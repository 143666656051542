import _ from 'lodash';

export const GET_MESSAGES = 'GET_MESSAGES';

export const ADD_MESSAGE_TO_ARRAY = 'ADD_MESSAGE_TO_ARRAY';
export const REMOVE_MESSAGE_FROM_ARRAY = 'REMOVE_MESSAGE_FROM_ARRAY';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

const state = {
  messages: [],
};

const getters = {
  [GET_MESSAGES](state) {
    return state.messages;
  },
};

const mutations = {
  [ADD_MESSAGE_TO_ARRAY](state, { message, type }) {
    if (!type) {
      type = 'success';
    }
    const index = (message + Math.random()).replace(/ /g, '');
    state.messages.push({
      message,
      type,
      index,
    });
  },
  [REMOVE_MESSAGE_FROM_ARRAY](state, index) {
    state.messages = _.filter(state.messages, (message) => message.index !== index) || [];
  },
};

const actions = {
  [ADD_MESSAGE]({ commit }, data) {
    commit(ADD_MESSAGE_TO_ARRAY, data);
  },
  [REMOVE_MESSAGE]({ commit }, index) {
    commit(REMOVE_MESSAGE_FROM_ARRAY, index);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
