import Vue from 'vue';
import {
  ENDPOINT_ROOM_DETAILS,
} from '@/api/handler.endpoints';
import {
  POST_ROOM_DETAILS_MESSAGE,
} from '@/api/handler.methods';

export const sendRoomDetails = (reservationId, roomId, type, contact) => {
  return Vue.axios.post(ENDPOINT_ROOM_DETAILS, {
    reservation_id: reservationId,
    room_id: roomId,
    type,
    contact,
  })
    .then(({data}) => data.responseBody || [])
    .catch((error) => {
      throw error;
    });
};

export default {
  [POST_ROOM_DETAILS_MESSAGE]: sendRoomDetails,
};
