<template>
  <div v-if="dialog" class="confirm-dialog-container">
    <div class="confirm-dialogs">
      <Dialog :message="dialog.message"
        :successCallback="dialog.successCallback"
        :dismissCallback="dialog.dismissCallback || null"
        :isNormalModal="dialog.isNormalModal"
        @close="closeDialog"
      />
    <div class="confirm-dialogs__backdrop"/>
    </div>
  </div>
</template>

<script>
import Dialog from './Dialog';
import {
  GET_DIALOG,
  CLOSE_DIALOG_ACTION,
} from '@/store/confirmDialog';

export default {
  name: 'ConfirmDialog',
  components: {
    Dialog,
  },
  computed: {
    dialog() {
      return this.$store.getters[GET_DIALOG];
    },
  },
  methods: {
    closeDialog() {
      this.$store.dispatch(CLOSE_DIALOG_ACTION);
    },
  },
};
</script>

<style lang="scss">
  .confirm-dialog-container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 1.5rem;
    z-index: 1000;
  }

  .confirm-dialogs {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 11;

    &__backdrop {
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      background: $white-color;
      opacity: 0.5;
      z-index: 10;
    }
  }
</style>
