<template>
  <label :class="`form-control form-control--${type}`">
    <span v-if="title && type !== 'radio' && type !== 'checkbox'" :class="{'required': required}">{{ title }}</span>
    <input :type="type"
      :value="value"
      :name="name"
      :class="{
        'form-input': true,
        'invalid': error,
      }"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="updateValue($event.target.value)"
      @change="($event) => {this.type === 'checkbox' ? null: updateValue($event.target.value)}"
    />
    <span v-if="title && (type === 'radio' || type === 'checkbox')" :class="{'required': required}">{{ title }}</span>
    <span v-if="error" class="error-message">
      <slot name="error">
        {{ error }}
      </slot>
    </span>
  </label>
</template>

<script>
export default {
  name: 'Input',
  props: {
    value: {
      required: true,
    },
    name: {
      type: String,
      default: null,
      required: false,
    },
    title: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue(value) {
      if (this.type === 'checkbox') {
        return this.$emit('input', !value);
      }
      if (this.type === 'radio') {
        return this.$emit('input', value);
      }
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss">
  .form-control {
    display: block;
    position: relative;

    &--radio,
    &--checkbox {
      display: inline-flex;
      align-items: center;

      span {
        margin-bottom: 0;
        margin-left: .25rem;
      }
    }
  }

  .form-input {
    width: 100%;
    height: 3.125rem;
    border: solid 1px #e2e2e2;
    font-size: 1rem;
    line-height: 1rem;
    text-align: left;
    color: #4a4a4a;
    padding: 0 2rem 0 1rem;
    box-sizing: border-box;
    border: 1px solid #e2e2e2;
    background: #fff;
    -webkit-appearance: none;
    @include border-radius(.5rem);

    &.invalid {
      border: solid 1px $red-color;
    }

    &[type=radio],
    &[type=checkbox] {
      width: 1.25rem;
      height: 1.25rem;
      padding: 0;
      border-radius: 0;
    }

    &[type=checkbox]:checked {
      background: $primary-color;
      box-shadow: inset 0 0 0 3px $secondary-body-bg;
    }

    &[type=radio]:checked {
      background: $primary-color;
    }
  }

  .error-message {
    position: absolute;
    width: 100%;
    left: 0;
    top: .2rem;
    font-size: .6rem;
    text-align: left;
    color: $red-color;
    padding: 0 1rem;
    white-space: nowrap;
  }
</style>
