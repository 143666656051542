export const LOGIN = 'LOGIN';
export const GET_RESERVATION = 'GET_RESERVATION';
export const POST_DISPENSE_CARD = 'POST_DISPENSE_CARD';
export const POST_ROOM_CHECKOUT = 'POST_ROOM_CHECKOUT';
export const POST_ROOM_DETAILS_MESSAGE = 'POST_ROOM_DETAILS_MESSAGE';
export const POST_GUEST_AUTHORIZE = 'POST_GUEST_AUTHORIZE';
export const KIOSK_STATUS = 'GET_KIOSK_STATUS';
export const REFRESH_PROPERTY_DATA = 'GET_REFRESHED_PROPERTY_DATA';
export const POST_CHECKIN = 'POST_CHECKIN';
export const POST_TTLOCK_DATA = 'POST_TTLOCK_DATA';
