import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Home from '../views/Home.vue';

import { idleLogout, idleLogoutClean } from '../middleware/idleLogout';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      middleware: idleLogoutClean,
    },
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      middleware: idleLogoutClean,
    },
  },
  {
    path: '/no-internet',
    name: 'no-internet',
    component: () => import('../views/NoInternet.vue'),
    meta: {
      middleware: idleLogoutClean,
    },
  },
  {
    path: '/reservation/:type',
    name: 'reservation',
    component: () => import('../views/Reservation.vue'),
    meta: {
      middleware: idleLogoutClean,
    },
  },
  {
    path: '/issue-card',
    name: 'issue-card',
    component: () => import('../views/IssueCard.vue'),
    meta: {
      middleware: idleLogout,
    },
  },
  {
    path: '/lost-card/success/:id',
    name: 'issue-card-lost-card-success',
    component: () => import('../views/SpareCardSuccess.vue'),
    meta: {
      middleware: idleLogout,
    },
  },
  {
    path: '/issue-card/:type',
    name: 'issue-card-type',
    component: () => import('../views/IssueCard.vue'),
    meta: {
      middleware: idleLogout,
    },
  },
  {
    path: '/room/directions/:id',
    name: 'room-directions',
    component: () => import('../views/RoomDirections.vue'),
    meta: {
      middleware: idleLogout,
    },
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('../views/CheckOutRoom.vue'),
    meta: {
      middleware: idleLogoutClean,
    },
  },
  {
    path: '/checkout/success/:id',
    name: 'checkout-success',
    component: () => import('../views/CheckOutSuccess.vue'),
    meta: {
      middleware: idleLogout,
    },
  },
  {
    path: '/checkout/error/:id',
    name: 'checkout-error',
    component: () => import('../views/CheckOutError.vue'),
    meta: {
      middleware: idleLogout,
    },
  },
  {
    path: '/checkin/list',
    name: 'checkin-list',
    component: () => import('../views/CheckInList.vue'),
    meta: {
      middleware: idleLogout,
    },
  },
  {
    path: '/checkin/add',
    name: 'checkin-add',
    component: () => import('../views/CheckInAdd.vue'),
    meta: {
      middleware: idleLogout,
    },
  },
  {
    path: '/checkin/missing',
    name: 'checkin-missing',
    component: () => import('../views/CheckInMissing.vue'),
    meta: {
      middleware: idleLogout,
    },
  },
  {
    path: '/guest-authentication/:type',
    name: 'guest-authentication-type',
    component: () => import('../views/GuestAuthentication.vue'),
    meta: {
      middleware: idleLogoutClean,
    },
  },
  {
    path: '/no-rooms-reservation',
    name: 'no-rooms-reservation',
    component: () => import('../views/NoRoomsReservation.vue'),
    meta: {
      middleware: idleLogout,
    },
  },
  {
    path: '/kiosk-version-mismatch',
    name: 'kiosk-version-mismatch',
    component: () => import('../views/KioskVersionMismatch.vue'),
    meta: {
      middleware: idleLogoutClean,
    },
  },
];

const router = new VueRouter({
  routes,
});

// Used code from:
// https://markus.oberlehner.net/blog/implementing-a-simple-middleware-with-vue-router/

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) {
    return context.next;
  }

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
