<template>
  <button :class="`button button-${view} button-${size} ${loading ? 'loading' : ''}`"
          :type="type"
          ref="tiBtn"
          @click="clickEvent"
  >
    <slot v-if="!loading" />
    <span v-else class="button-loading">
      <LoadingOvalSvg />
    </span>
    <transition-group>
      <span class="ripple"
        v-bind:ref="'ripple-' + i"
        v-bind:key="'ripple' + i"
        v-for="(val, i) in visibleTipples"
        v-bind:style="{'top': val.y + 'px', 'left': val.x + 'px'}"
        v-on:animationend="rippleEnd(i)">
      </span>
    </transition-group>
  </button>
</template>

<script>
import _ from 'lodash';
import LoadingOvalSvg from '@/assets/images/loadingOval.svg';

export default {
  name: 'ButtonInput',
  components: {
    LoadingOvalSvg,
  },
  data() {
    return {
      ripples: [],
    };
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'button',
    },
    view: {
      type: String,
      required: false,
      default: 'primary',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'regular',
    },
  },
  computed: {
    visibleTipples() {
      return _.filter(this.ripples, {show: true});
    },
  },
  methods: {
    clickEvent(event) {
      // this.animateRipple(event);
      this.$emit('click', event);
    },
    animateRipple: function(e) {
      let el = this.$refs.tiBtn;
      let pos = el.getBoundingClientRect();

      this.ripples.push({
        x: e.clientX - pos.left,
        y: e.clientY - pos.top,
        show: true,
      });
    },
    rippleEnd: function(i) {
      this.ripples[i].show = false;
    },
  },
};
</script>

<style lang="scss">
  .button {
    font-size: .875rem;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 1px;
    color: $hotel-title-color;
    padding: .8125rem 3rem;
    border: 0 none;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    outline: none;
    transition: all .1s ease-in-out;

    @include border-radius(22px);
    @include box-shadow(0, 4px, 6px, 0, $shadow-color);

    &.loading {
      width: 3.125rem !important;
      height: 3.125rem;
      padding: 0.1rem .5rem 0;
      border-radius: 50%;
      margin: auto;

      .button-loading {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 2rem;
          height: 2rem;
          margin: 0;
        }
      }
    }

    &:disabled:not(.loading) {
      opacity: 0.8;
      cursor: not-allowed;
      border-color: $disabled-color;

      svg {
        g {
          stroke: $disabled-color;
        }
      }
    }

    &-primary {
      background-color: $primary-color;

      &:hover {
        background-color: $gradient-color-dark-hover;
      }

      &:disabled {
        background: $disabled-color;
        color: $disabled-text-color;
        box-shadow: none;
      }

      &-outline-classic {
        color: $primary-color;
        background: transparent;
        border: solid 2px $primary-color;
      }

      &-outline {
        color: $primary-color;
        line-height: 10px;
        background: transparent;
        border: solid 2px $primary-color;
        padding: 15px 16px;
        @include box-shadow(0, 0, 0, 0, transparent);
      }
    }

    &-danger {
      background-color: $red-color;

      &:disabled {
        background: $disabled-color;
        color: $disabled-text-color;
        box-shadow: none;
      }
    }

    &-outline {
      box-shadow: 0 0 0 2px $primary-color;
      background-color: $hotel-title-color;
      color: $primary-color;

      &:disabled {
        background: $disabled-color;
        color: $disabled-text-color;
        box-shadow: none;
      }
    }

    &-large {
      width: 16rem;
      font-size: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  .ripple {
    background-color: $gradient-color-light;
    width: 1rem;
    height: 1rem;
    position: absolute;
    border-radius: 50%;
    transform: translateX(-100%) translateY(-100%);
    mix-blend-mode: screen;
    animation: ripple 1250ms ease-out forwards, fade 1500ms ease-out forwards;
  }

  @keyframes ripple {
    0%   { transform: translate(-100%, -100%); }
    80%  { transform: translate(-100%, -100%) scale(50); }
    100% { transform: translate(-100%, -100%) scale(50); opacity: 0; }
  }

  @keyframes fade {
    0%   { opacity: 1; }
    100% { opacity: 0; }
  }
</style>
