import Vue from 'vue';
import {
  ENDPOINT_AUTH,
} from '@/api/handler.endpoints';
import {
  LOGIN,
} from '@/api/handler.methods';

export const login = (email, password, propertyPin) => {
  return Vue.axios.post(ENDPOINT_AUTH, {
    email,
    password,
    pin: propertyPin,
  })
    .then(({data}) => data.responseBody || [])
    .catch((error) => {
      throw error;
    });
};

export default {
  [LOGIN]: login,
};

