export const GET_DIALOG = 'GET_DIALOG';

export const ADD_DIALOG = 'ADD_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';

export const CONFIRM_DIALOG_OPEN = 'CONFIRM_DIALOG_OPEN';
export const CLOSE_DIALOG_ACTION = 'CLOSE_DIALOG_ACTION';

const state = {
  dialog: null,
};

const getters = {
  [GET_DIALOG](state) {
    return state.dialog;
  },
};

const mutations = {
  [ADD_DIALOG](state, { message, successCallback, dismissCallback, isNormalModal }) {
    state.dialog = {
      message,
      successCallback,
      dismissCallback,
      isNormalModal,
    };
  },
  [CLOSE_DIALOG](state) {
    state.dialog = null;
  },
};

const actions = {
  [CONFIRM_DIALOG_OPEN]({ commit }, data) {
    commit(ADD_DIALOG, data);
  },
  [CLOSE_DIALOG_ACTION]({ commit }) {
    commit(CLOSE_DIALOG);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};