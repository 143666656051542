import api from '@/api/handler';
import * as endpoints from '@/api/handler.endpoints';
import * as methods from '@/api/handler.methods';
import {
  UPDATE_PROPERTY_DATA,
  GET_PROPERTY_DETAIL,
} from '@/store/property';

const propertyDataRefresher = (vm) => {
  setInterval(() => {
    if (!vm.$store.getters[GET_PROPERTY_DETAIL].id) {
      return ;
    }

    api(vm, endpoints.ENDPOINT_REFRESH_PROPERTY_DATA, methods.REFRESH_PROPERTY_DATA)()
      .then((data) => {
        if (data.metadata.success) {
          vm.$store.dispatch(UPDATE_PROPERTY_DATA, data.data);
        }
      })
      .catch((err) => {
        console.log('Error while refreshing data', err);
      });
  }, parseInt(process.env.VUE_APP_PROPERTY_REFRESH_INTERVAL, 10) * 60 * 1000);
};

export default propertyDataRefresher;
